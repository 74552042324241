// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu60600: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { primary, white, text4, black, accent },
    },
  } = themeVars;

  return {
    HeaderMenuDesktop: {
      menuItem: {
        padding: [0, margins.xm],
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        color: black,
        '&:hover, &:focus': {
          color: accent,
        },
      },
      menuItemActive: {
        color: accent,
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        color: white,
        borderBottom: 'none',
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: primary,
        },
      },
    },
  };
};
