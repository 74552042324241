// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu60600: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { black, neutral1, text3, inform },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      selectorWrap: {
        color: black,
      },
      item: {
        background: neutral1,
        '&:hover,&.active': { color: text3, background: neutral1 },
      },
    },

    dropdownMobile: {
      select: {
        color: inform,
      },
    },
  };
};

