// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch60600: JSSThemeDefaultSearchType = (themeVars) => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: {
        white,
        background3,
        background1,
        text3,
        neutral2,
        neutral1,
        primary,
        black,
      },
    },
  } = themeVars;
  return {
    SportsbookSearchSuggest: {
      input: {
        background: `${white} !important`,
        border: 'none !important',
        borderRadius: '3px !important',
        color: `${black} !important`,
        height: '40px !important',
        lineHeight: '40px !important',
        padding: `${margins.md}px !important`,
      },
    },
    SearchBar: {
      searchContainer: {
        margin: margins.md,
        borderRadius: borderRadius.lg,
        overflow: 'hidden',
        background: white,
      },
      iconContainer: {
        color: background3,
      },
      resultBackground: {
        top: -60,
      },
      resultContainer: {
        width: 'calc(100vw - 20px)',
        background: neutral1,
        maxHeight: `calc(100vh - ${134 + margins.md}px)`,
      },
    },
    SportsbookSearchResult: {
      sportsbookSearchResultRoot: {
        background: '#fff',
      },
      marketCountLink: {
        '&:hover': {
          color: white,
        },
      },
      title: {
        color: text3,
        height: '35px',
        lineHeight: '35px',
        background: neutral2,
        textTransform: 'lowercase',
        padding: [0, margins.lg],
        marginLeft: -margins.lg,
        marginRight: -margins.lg,
      },
      event: {
        marginLeft: -margins.xs,
        marginRight: -margins.xs,
      },
      description: {
        lineHeight: 1.3,
      },
      marketCount: {
        color: white,
        background: background1,
        borderRadius: borderRadius.md,
        height: '25 !important',
        '&:hover': {
          background: primary,
          color: white,
        },
      },
    },
  };
};
