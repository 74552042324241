// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60600: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, black },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: black,
      color: white,
    },
    socialHide: {
      display: 'block',
    },
    socialCont: {
      margin: 0,
    },
    socialWrap: {
      display: 'none',
    },
    menuWrapper: {
      width: '60%',
      '@media screen and (max-width: 1009px)': {
        paddingBottom: '0',
      },
    },
    licenseLink: {
      paddingRight: 0,
      textDecoration: 'none',
      '&:hover, &:active, &:focus': {
        textDecoration: 'none',
      },
    },
    headerMenu: {
      fontWeight: 'bold',
      '@media screen and (max-width: 1370px)': {
        fontWeight: 'bold',
      },
    },
    legal: {
      paddingTop: '30px',
      borderTop: '1px solid #4d4d4d',
    },
    logosContainer: {
      paddingBottom: 15,
    },
    logoSvg: {
      width: 90,
      maxHeight: 30,
    },
    footerContainer: {
      '@media screen and (max-width: 683px)': {
        padding: '20px 10px 40px',
      },
    },
  };
};
