// @flow
import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';
// import { messageHandlerSettings } from '../../diffusion/handlers/message';

export const JSSThemeSportsMenu60600: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { accent, primary, white, background2, black, background7 },
      colorCombinations: { whitePrimary },
    },
  } = themeVars;

  return {
    SportsMenuDesktop: {
      header: {
        color: white,
      },
      sport: {
        background: black,
        color: white,
        '&.active, &:hover, &:focus': {
          background: accent,
        },
      },
      region: {
        background: background7,
        color: white,
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        margin: 0,
        height: 30,
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: black,
        fontWeight: 'bold',
      },
      sportLink: {
        '&.active, &:hover': {
          color: accent,
          opacity: 1,
        },
      },
      list: {
        paddingTop: margins.xs,
      },
    },
    SubMenuListUIMobile: {
      link: {
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          ...whitePrimary,
        },
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: black,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        color: black,
        '&.active': {
          color: primary,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.md,
      },
      sportName: {
        fontSize: fontSizes.md,
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: black,
        color: primary,
      },
      title: {
        color: black,
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: background2,
      },

      sportLink: {
        color: black,
      },
    },
  };
};
