// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile60600: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorTypes: { primary, white, black, background7 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      menuRight: {
        color: white,
        background: black,
      },
      headerContainer: {
        background: white,
        color: primary,
      },
      menuContainer: {
        color: white,
        background: black,
      },
      iconContainer: {
        color: black,
      },
    },
  };
};
