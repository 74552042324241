// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
  getJSSThemePatterns,
} from '../JSSThemeDefault';
import { JSSThemeHeaderMenu60600 } from './JSSThemeHeaderMenu60600';
import { JSSThemeHeader60600 } from './JSSThemeHeader60600';
import { JSSThemeBetslip60600 } from './JSSThemeBetslip60600';
import { JSSThemeSportsMenu60600 } from './JSSThemeSportsMenu60600';
import { JSSThemeLayout60600 } from './JSSThemeLayout60600';
import { JSSThemeLanguageSwitcher60600 } from './JSSThemeLanguageSwitcher60600';
import { JSSThemeSearch60600 } from './JSSThemeSearch60600';
import { JSSThemeCoupons60600 } from './JSSThemeCoupons60600';
import { JSSThemeBannerItems60600 } from './JSSThemeBannerItems60600';
import { JSSThemeDefaultWidgets60600 } from './JSSThemeWidgets60600';
import { JSSThemeForm60600 } from './JSSThemeForm60600';
import { JSSThemeAuthForm60600 } from './JSSThemeAuthForm60600';
import { JSSThemeRegistration60600 } from './JSSThemeRegistration60600';
import { JSSThemeBalance60600 } from './JSSThemeBalance60600';
import { JSSThemeMyBets60600 } from './JSSThemeMyBets60600';
import { JSSThemeLive60600 } from './JSSThemeLive60600';
import { JSSThemeCasino60600 } from './JSSThemeCasino60600';
import { JSSThemeHeaderMobile60600 } from './JSSThemeHeaderMobile60600';
import { JSSThemeOutcomes60600 } from './JSSThemeOutcomes60600';
import { JSSThemeAccountSection60600 } from './JSSThemeAccountSection60600';
import { JSSThemePopularLeagues60600 } from './JSSThemePopularLeagues60600';
import { JSSThemeFooter60600 } from './JSSThemeFooter60600';
import { JSSThemeCookies60600 } from './JSSThemeCookies60600';
import { JSSThemeBonuses60600 } from './JSSThemeBonuses60600';
import { JSSThemeDropdownMenu60600 } from './JSSThemeDropdownMenu60600';
import { JSSThemeTooltip60600 } from './JSSThemeTooltip60600';
import { JSSThemeFooterAll60600 } from './JSSThemeFooterAll60600';
import { JSSThemePromotions60600 } from './JSSThemePromotions60600';
import { JSSThemeSitemap60600 } from './JSSThemeSitemap60600';
import { JSSThemeAffiliates60600 } from './JSSThemeAffiliates60600';
import { applyOnlyDesktop } from '../jss-helpers';

export const colorTypes60600: ColorTypes = {
  ...colorTypes,
  text2: '#73747d',
  text3: '#333',
  text4: '#1F71DB',
  text5: '#001c35',
  primary: '#FFA600',
  inform: '#f30100',
  error: '#f30100',
  accent: '#126DE2',
  background1: '#6d6d6d',
  background2: '#EBEBEB',
  background3: '#0b1258',
  background4: '#bbb',
  background5: '#989797',
  background6: '#ccc',
  background7: '#313131',
  neutral1: '#e7e7e7',
  neutral2: '#c1cad0',
};

export const colorCombinations60600 = getColorCombinations(colorTypes60600);

export const JSSThemeVariables60600: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations60600,
    colorTypes: colorTypes60600,
  },
};

export const JSSTheme60600: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        accent,
        white,
        background2,
        black,
        background5,
        text5,
        text4,
        primary,
        text2,
      },
    },
  } = themeVars;
  return {
    banners: JSSThemeBannerItems60600(themeVars),
    header: JSSThemeHeader60600(themeVars),
    headerMenu: JSSThemeHeaderMenu60600(themeVars),
    betslip: JSSThemeBetslip60600(themeVars),
    sportsMenu: JSSThemeSportsMenu60600(themeVars),
    layout: JSSThemeLayout60600(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60600(themeVars),
    search: JSSThemeSearch60600(themeVars),
    coupons: JSSThemeCoupons60600(themeVars),
    widgets: JSSThemeDefaultWidgets60600(themeVars),
    form: JSSThemeForm60600(themeVars),
    authForm: JSSThemeAuthForm60600(themeVars),
    registration: JSSThemeRegistration60600(themeVars),
    balance: JSSThemeBalance60600(themeVars),
    casino: JSSThemeCasino60600(themeVars),
    mybets: JSSThemeMyBets60600(themeVars),
    accountSection: JSSThemeAccountSection60600(themeVars),
    live: JSSThemeLive60600(themeVars),
    cookies: JSSThemeCookies60600(themeVars),
    popularLeagues: JSSThemePopularLeagues60600(themeVars),
    Heading: {
      common: {
        color: text5,
        padding: [margins.xs, margins.xm, 0],
        fontSize: 30,
        '@media (pointer: coarse)': {
          color: white,
        },
        '&.headingMobile': {
          color: black,
          // padding: [margins.xs, margins.xm, 0],
        },
        none: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    NoEventsMessage: {
      root: {
        color: `${text4} !important`,
      },
    },
    DropDownUI: {
      title: {
        '&:hover': {
          opacity: 1,
        },
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        background: background2,
      },
      title: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxxl,
        },
      },
      desciption: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
    },
    Button: {
      small: {
        padding: '5px 10px',
      },
      block: {
        '@media (pointer: coarse)': {
          fontWeight: 'bold',
        },
      },
      large: {
        padding: [margins.md, margins.xl],
      },
      linkDefault: {
        color: black,
        '@media not all and (pointer: coarse)': {
          //applyOnlyDesktop
          '&:hover': {
            color: background5,
          },
        },
      },
      secondary: {
        color: white,
        background: text4,
        '&:hover': {
          color: white,
          background: primary,
        },
      },
      linkSecondary: {
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            color: black,
          },
        }),
      },
    },
    Geoblock: {
      rootGeoblock: {
        background: black,
      },
    },
    bonuses: JSSThemeBonuses60600(themeVars),
    tooltip: JSSThemeTooltip60600(themeVars),
    footer: JSSThemeFooter60600(themeVars),
    footerAll: JSSThemeFooterAll60600(themeVars),
    headerMobile: JSSThemeHeaderMobile60600(themeVars),
    outcomes: JSSThemeOutcomes60600(themeVars),
    promotions: JSSThemePromotions60600(themeVars),
    sitemap: JSSThemeSitemap60600(themeVars),
    affiliates: JSSThemeAffiliates60600(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60600(themeVars),
    LiveNow: {
      title: {
        background: `transparent !important`,
        textTransform: 'uppercase !important',
        borderRadius: '0px !important',
        width: '100% ',
        margin: '0 !important',
        padding: '0 !important',
        color: `${black} !important`,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        color: text4,
        background: white,
      },
    },
    OfflineMessage: {
      popup: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: 'white',
      },
    },
    NoBetsMessage: {
      root: {
        background: white,
        fontSize: fontSizes.sm,
      },
    },
    BetslipMybetsToggler: {
      root: { background: `${primary} !important` },
      badge: { color: `${primary} !important` },
      close: { background: `${primary} !important` },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
          background: background2,
        },
      },
      link: {
        color: black,
      },
      h2: {
        background: text4,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        borderTop: `1px solid ${accent}`,
        paddingBottom: 3,
      },
    },
    SessionTimer: {
      TimerSelectorWrap: {
        color: black,
      },
    },
    MobileSessionTimer: {
      TimerSelectorWrap: {
        color: text2,
      },
    },
  };
};

export const getJSSThemePatterns60600: GetJSSThemePatternsType =
  getJSSThemePatterns;
