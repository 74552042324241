// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems60600: JSSThemeDefaultBannerItemsType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text2, border2, border1 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerItemSportsbookCarouselMobile: {
      header: {
        fontSize: fontSizes.xl * 2,
        lineHeight: 'auto',
      },
      description: {
        textAlign: 'left',
        fontSize: fontSizes.md,
      },
      imgContainer: {
        alignItems: 'left',
        minHeight: 140,
        justifyContent: 'flex-start',
        padding: '10px 15px',
      },
    },
    BannerContent: {
      bannerContentRoot: {
        color: white,
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentFill: {
        padding: '0',
      },
      descriptionMobile: {
        fontWeight: 'bold',
        textShadow: '3px 3px 5px rgba(0,0,0,.9)',
        padding: [margins.md, 0, 0],
      },
      headerMobile: {
        textShadow: '3px 3px 13px rgba(0,0,0,.9)',
      },
      bannerContentSportsbookCarousel: {
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentPromo: {
        padding: [margins.md, margins.xs],
        maxWidth: 500,
      },
      headerSportsbookCarousel: {
        fontSize: fontSizes.xl * 3,
        fontWeight: 'bold',
        color: white,
      },
      headerPromo: {
        fontSize: '26px',
        lineHeight: 'normal',
        textShadow: 'none',
        fontWeight: 'bold',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.lg,
        color: white,
      },
      descriptionCasinoCarousel: {
        fontSize: [fontSizes.lg],
        color: white,
        padding: [margins.xs, 0],
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.md,
        lineHeight: '20px',
        padding: '0',
        margin: [margins.xs, 0],
      },
      textWrapperFill: {
        padding: '0',
      },
      buttonsWrapperSportsbookCarousel: {
        margin: [margins.md, 0, 0, 0],
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
        '@media (pointer: coarse)': {
          marginTop: margins.xs,
        },
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: 0,
        marginRight: '0',
        borderRadius: '5px',
      },
      heightFix: {
        minHeight: '258px',
      },
      imgContainer: {
        height: '100%',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        width: 'auto',
        padding: '0',
        backgroundColor: 'none',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: border2,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: border1,
      },
      descriptionTeams: {
        color: '#000',
      },
    },
    SideBanner: {
      containerWithContent: {
        backgroundPosition: 'left center',
        padding: '0',
      },
    },
    BannerItemCasinoCarousel: {
      mobile: {
        height: 'auto',
        '&:after': {
          background: `linear-gradient(
            to right,
            rgba(10, 31, 50, 1) 0%,
            rgba(10, 31, 50, 0) 10%,
            rgba(10, 31, 50, 0) 100%
          )`,
        },
      },
    },
  };
};
