// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher60600: JSSThemeDefaultLanguageSwitcherType = themeVars => {
  const {
    colors: {
      colorTypes: { inform },
    },
  } = themeVars;
  return {
    LanguageSelectComponentMobile: {
      select: {
        color: inform,
      },
    },
  };
};
