// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes60600: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: {
        primary,
        white,
        background1,
        inform,
        background2,
        accent,
        // black,
      },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        background: accent,
        color: white,
        '&.active': {
          background: primary,
        },
        '&.suspended': {
          opacity: 0.4,
        },
      },
      priceValue: {
        color: white,
      },
    },
    Outcome: {
      value1x2: {
        opacity: 0.8,
      },
      root: {
        borderRadius: borderRadius.md,
        background: accent,
        color: '#fff',
        '&.active': {
          background: primary,
        },
        '@media (pointer: coarse)': {
          height: 35,
          lineHeight: '35px',
          padding: [0, margins.md],
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: primary,
            // color: black,
          },
        },
        '&.disabled': {
          background: background1,
          color: background2,
          opacity: '.5',
        },
      },
    },
  };
};
