import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60600: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background2, black, background6 },
    },
    margins,
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        color: '#000',
      },
    },
    OPMPageComponent: {
      OPMroot: {
        margin: [0, margins.xm],
      },
      heading: {
        color: black,
      },
    },
    PendingWithdrawal: {
      wrapper: {
        padding: [0, margins.xm, margins.xm],
        color: black,
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: background2,
        width: 300,
        paddingTop: margins.md,
      },
      accountPageRightContent: {
        background: background6,
        color: black,
      },
    },
    BetsHistory: {
      BetsHistoryHeading: {
        color: '#000',
        '@media (pointer: coarse)': {
          color: '#000',
        },
      },
      filtersTitle: {
        color: '#000',
        '@media (pointer: coarse)': {
          color: '#000',
        },
      },
      filterSelectionItem: {
        '&[for="casino"]': {
          display: 'none',
        },
        '&[for="virtuals"]': {
          display: 'none',
        },
      },
    },
    OPMForms: {
      form: {
        background: background2,
        color: '#000',
      },
    },
    ResponsibleGaming: {
      depositFormColumn: {
        width: '132px',
      },
      changeLimitsButtonDiv: {
        maxWidth: 275,
        '@media (max-width: 460px)': {
          marginLeft: 0,
          width: '100%',
        },
      },
    },
  };
};
