// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60600,
  JSSThemeVariables60600,
  getJSSThemePatterns60600,
} from './JSSTheme60600';
import { GeoblockPage60600 } from './GeoblockPage60600';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60600').then(module => module.FullDesktop60600),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60600').then(module => module.FullMobile60600),
  }),
  JSSTheme: JSSTheme60600,
  JSSThemeVariables: JSSThemeVariables60600,
  JSSThemePatterns: getJSSThemePatterns60600,
  GeoblockPage: GeoblockPage60600,
});
