// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates60600: JSSThemeDefaultAffiliatesType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, accent, black, primary, background5 },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      color: black,
      display: 'none',
    },
    affiliatesCommission: {
      commissionHeader: {
        color: black,
        padding: ['70px 20px 50px'],
        '@media (pointer: coarse)': {
          padding: '30px 20px 30px',
          fontSize: '48px',
          lineHeight: '58px',
        },
      },
      commissionContent: {
        '@media (pointer: coarse)': {
          padding: '60px 20px',
        },
      },
    },
    affiliatesHome: {
      affiliateRoot: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      topBannerHeader: {
        color: black,
      },
      topBannerDescription: {
        color: black,
      },
      productsRoot: {
        background: black,
      },
      step: {
        boxShadow: '0px 8px 21px 14px rgb(161 146 97 / 15%)',
      },
      productsButton: {
        color: '#fff',
        backgroundColor: accent,
        boxShadow: 'none',
        background: accent,
        transition: 'background 1s ease-out',
        textShadow: 'none',
        '&:hover': {
          background: primary,
          backgroundColor: primary,
          color: '#fff',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          color: '#fff',
          background: primary,
          backgroundColor: primary,
          boxShadow: 'none',
        },
      },
      topBannerButton: {
        color: '#fff',
        backgroundColor: primary,
        boxShadow: 'none',
        background: primary,
        transition: 'background 1s ease-out',
        textShadow: 'none',
        '&:hover': {
          background: accent,
          backgroundColor: accent,
          color: '#fff',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          color: '#fff',
          background: primary,
          backgroundColor: primary,
          boxShadow: 'none',
        },
      },
    },
    affiliateHeader: {
      loginBarRoot: {
        background: white,
        '@media (pointer: coarse)': {
          background: '#3E3E3E',
        },
      },
      topMenuItem: {
        '&:hover': {
          color: accent,
        },
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      topMenuItemActive: {
        color: `${accent} !important`,
      },
      SignupIcon: {
        fill: black,
        '@media (pointer: coarse)': {
          fill: white,
        },
      },
      SignupRoot: {
        color: black,
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      reasonsIcon: {
        fill: primary,
      },
      burgerBar: {
        background: black,
      },
    },
    affiliatesFooter: {
      productsTerms: {
        color: background5,
      },
      footerRoot: {
        background: black,
      },
      footer: {
        color: background5,
        opacity: '1',
      },
    },
    affiliatesProducts: {
      productsButton: {
        color: '#fff',
        backgroundColor: primary,
        boxShadow: 'none',
        background: primary,
        transition: 'background 1s ease-out',
        textShadow: 'none',
        '&:hover': {
          background: accent,
          backgroundColor: accent,
          color: '#fff',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          color: '#fff',
          background: primary,
          backgroundColor: primary,
          boxShadow: 'none',
        },
      },
      productsSubHeader: {
        padding: '50px 0',
        '@media (pointer: coarse)': {
          padding: '50px 0 30px',
          fontSize: '36px',
        },
      },
      productsHeader: {
        color: black,
        padding: ['70px 20px 50px'],
        '@media (pointer: coarse)': {
          padding: '30px 20px 30px',
          fontSize: '48px',
          lineHeight: '58px',
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: primary,
        },
      },
      contactsHeader: {
        color: black,
        padding: ['70px 20px 50px'],
        '@media (pointer: coarse)': {
          padding: '30px 20px 30px',
          fontSize: '48px',
          lineHeight: '58px',
        },
      },
    },
    affiliatesFAQ: {
      FAQHeader: {
        color: black,
        padding: ['70px 20px 50px'],
        '@media (pointer: coarse)': {
          padding: '30px 20px 30px',
          fontSize: '48px',
          lineHeight: '58px',
        },
      },
      FAQquestion: {
        padding: '30px 0 15px',
      },
    },
    affiliatesSignup: {
      signupHeader: {
        color: black,
        padding: ['70px 20px 50px'],
        '@media (pointer: coarse)': {
          padding: '30px 20px 30px',
          fontSize: '48px',
          lineHeight: '58px',
        },
      },
      signupIframeWrapper: {
        padding: '20px 0',
        maxWidth: 1216,
        margin: 'auto',
      },
    },
  };
};
