// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeCookies60600 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { text5, black },
    },
  } = themeVars;
  return {
    cookiesPolicy: {
      CookiesPolicyRoot: {
        color: text5,
        '@media (pointer: coarse)': {
          color: black,
        },
      },
      CookiesPolicyTableHeading: {
        color: text5,
        '@media (pointer: coarse)': {
          color: black,
        },
      },
    },
  };
};
