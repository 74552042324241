// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60600: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background2, accent, white, black },
    },
  } = themeVars;

  return {
    MyBets: {
      content: {
        background: background2,
      },
      myBetsTabsItem: {
        background: white,
        color: black,
        '&:hover': {
          background: accent,
          color: white,
        },
        '&.active': {
          background: accent,
          color: white,
        },
      },
    },
  };
};
