// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60600: JSSThemeDefaultPromotionsType = themeVars => {
  const {
    margins,
    // colors: {
    //   colorTypes: { white },
    // },
  } = themeVars;
  return {
    PromotionUI: {
      itemDescription: {
        '@media (pointer: coarse)': {
          padding: [margins.md, 0],
        },
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        padding: 0,
      },
      promotionsWrapper: {
        padding: 0,
        '@media (pointer: coarse)': {
          padding: [0, margins.xm],
        },
      },
    },
  };
};
