// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues60600: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    fontSizes,
    colors: {
      colorTypes: { black, white, accent, primary },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      header: {
        fontSize: fontSizes.xxl,
        color: white,
        '@media (pointer: coarse)': {
          fontWeight: 'normal !important',
        },
      },
      link: {
        color: 'white !important',
        '&:hover': {
          opacity: 1,
          background: accent,
        },
        '@media (pointer: coarse)': {
          '&:hover': {
            background: primary,
          },
        },
      },
      img: {
        // filter: 'invert(1)',
      },
      root: {
        background: black,
        '@media (pointer: coarse)': {
          background: black,
        },
      },
    },
  };
};
