// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive60600: JSSThemeDefaultLiveType = themeVars => {
  const {
    colors: {
      colorTypes: {
        inform,
        accent,
        background2,
        white,
        primary,
        neutral1,
        black,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    LiveEventView: {
      liveEventViewRoot: {
        marginTop: '0px !important',
      },
    },
    BannerSectionComponent: {
      bannerTitle: {
        background: `${accent} !important`,
      },
      bannerBadge: {
        color: `${accent} !important`,
      },
    },
    ScoreNumber: {
      wonScore: {
        color: `${inform} !important`,
      },
    },
    LiveCouponMarket: {
      favoriteMarketIcon: {
        color: `${accent} !important`,
      },
      favoriteMarketIconWatched: {
        color: `${accent} !important`,
      },
    },
    LiveMenuEvent: {
      LiveEventRanked: {
        background: neutral1,
      },
      LiveEventRankedDesktop: {
        background: 'white !important',
      },
    },
    LiveSportComponent: {
      panelHeading: {
        background: `${black} !important`,
        color: 'white !important',
        borderBottom: 'none !important',
        '&:hover': {
          background: `${primary} !important`,
          color: `${white} !important`,
        },
      },
    },
    LiveMenuFavoritesComponent: {
      favoritesPanelGroup: {
        background: `${black} !important`,
        paddingBottom: 6,
      },
      panelHeading: {
        background: `${black} !important`,
        color: 'white !important',
        borderBottom: 'none !important',
        '&:hover': {
          background: `${primary} !important`,
          color: `${white} !important`,
        },
      },
      favoritesMessage: {
        background: `${black} !important`,
        color: 'white !important',
      },
    },
    LiveLeague: {
      liveLeagueRoot: {
        margin: `${margins.xs}px  0px ${margins.md}px !important`,
      },
      liveLeaguePanelHeading: {
        padding: `0px ${margins.xs}px !important`,
        boxShadow: 'none !important',
        marginBottom: margins.xs,
        '-webkit-box-shadow': 'none !important',
        '&:hover': {
          background: `${background2} !important`,
        },
      },
      panelTitleLeagueName: {
        background: `transparent !important`,
        color: `${accent} !important`,
        fontWeight: 'bold !important',
        fontSize: fontSizes.md,
      },
      liveLeagueCollapseIcon: {
        color: `${accent} !important`,
      },
    },
    LiveMenuEventHeading: {
      eventHeading: {
        background: 'inherit !important',
        color: 'inherit !important',
      },
      eventHeadingTeams: {
        color: 'inherit !important',
        transition: 'none !important',
      },
      eventHeadingSuspended: {
        color: 'inherit !important',
        fontWeight: 'bold !important',
      },
    },
    LiveMenuRegularEvent: {
      liveMenuEventRoot: {
        background: `${background2} !important`,
        color: black,
        borderRadius: '3px',
        '&:hover, &.active': {
          background: `${primary} !important`,
          color: 'white !important',
        },
        liveMenuEventLink: {},
      },
      liveMenuEventInfo: {
        background: 'inherit !important',
        color: 'inherit !important',
      },

      liveMenuEventInfoChild: {
        paddingTop: '0px !important',
        background: 'inherit !important',
        color: 'inherit !important',
      },
    },
    LiveMenuRankedEvent: {
      liveMenuRankedEventRoot: {
        background: 'white !important',
        color: 'black !important',
        '&:hover, &.active': {
          background: `${inform} !important`,
          color: 'white !important',
        },
      },
      liveMenuRankedEventWrapper: {
        '&:hover, &.active': {
          background: `${inform} !important`,
          color: 'white !important',
        },
      },
      liveMenuRankedEventTeams: {
        transition: 'none !important',
      },
    },
    ClockScoreComponent: {
      clockScorePeriod: {
        color: 'inherit !important',
      },
      clockScoreResult: {
        color: 'inherit !important',
      },
    },
    Badge: {
      root: {
        background: `${inform}!important`,
        color: `${white}!important`,
      },
    },
  };
};
